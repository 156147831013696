<template>
  <div class="big">
      <div class="nine_title">
          <div class="nine_title_backimg" :style="{backgroundImage:'url('+ getInfoData.back_logo+')'}">
              <div class="nine_title_data">
                  <img src="../assets/img/cslogo.png" class="nine_title_data_img">
                  <div class="nine_title_data_user_is" v-if="isToken">
                      <span @click="handleZhu()">登录</span>
                      <i>/</i>
                      <span @click="handleD()">注册</span>
                  </div>
                  <div class="nine_title_data_user" v-else>
                      <div class="_user">
                            <img :src=this.$store.state.userdata.logo alt="" class="data_user_img">
                            <span class="data_user_name">{{this.$store.state.userdata.username}}</span>
                      </div>
                      <div class="_user_wallet">
                          <p><img src="../assets/logo.png" alt="" style="width:20px"> : {{this.$store.state.userdata.coin}}</p>
                          <button @click="$router.push({path:'/recharge'})">充值</button>
                      </div>
                      <span class="data_userout" @click="isout()">退出登录</span>
                  </div>
                  <img src="../assets/img/NineLattice/go.png" alt="" class="data_user_out" @click="$router.push({path:'/'})">
              </div>
          </div>
      </div>

      <div class="Notice">
        <!-- 三条通知栏 -->
        <!-- <div class="notice_san_big">
            <div class="notice_san" v-for="(item,index) in Notices" :key="index">
                <span class="notice_icon"><i class="iconfont">&#xe601;</i></span>
                <div class="notices">
                    <div class="notice_data">
                        <span>最近一小时</span>
                        <span>{{$options.filters.testfile(item.username)}}</span>
                        <span>获得了</span>
                        <span class="notice_data_prize">{{item.title}}</span>
                    </div>
                </div>
            </div>
        </div> -->
        <div class="notice_san_big">
            <span class="notice_icon" v-if="Notices.length != 0"><i class="iconfont">&#xe601;</i></span>
            <div class="notices">
                <div class="notice_data" v-for="(item,index) in Notices" :key="index">
                    <span>{{$options.filters.testfile(item.username)}}</span>
                    <span>获得了</span>
                    <span class="notice_data_prize">{{item.title}}</span>
                </div>
            </div>
        </div>

      </div>

      <div class="boxtitle">
          <!-- <img src="../assets/img/NineLattice/title.png" alt=""> -->
          <h1>{{getInfoData.title}}</h1>
      </div>

      <!-- 箱子 -->
      <div class="boxdata">
          <div class="boxdata_img" :style="{backgroundImage:'url('+ getInfoData.back_logo_2+')'}">
            <div class="box_prize_data_img" :style="{backgroundImage:'url('+ getInfoData.logo+')'}"></div>
          </div>
          <p class="boxdata_title">金必到手</p>
      </div>
      <div class="box_but" v-if="coin != -1">
          <button v-if="isrepages">开启中<i class="el-icon-loading" style="font-size: 20px;"></i></button>
          <button @click="nine" v-else>开启({{coin}})</button>
      </div>
      <div class="box_but" v-else>
          <button>已经全部获胜</button>
      </div>

      <!-- 抽奖部分  -->
      <div class="extract_prize">
          <div class="extract_prize_title">
              <h1 class="_title_h1">包含<span class="_title_h1_span">{{list.length}}</span>件奖品</h1>
              <h1 class="_title_h1">已获胜:<span class="_title_h1_span">{{havePrize.length}}</span>奖品</h1>
          </div>

          <ul class="prize_ul">
            <li v-for="(item,index) in list" :key="index">
                <div class="prize_ul_box" :style="{backgroundImage:'url('+ item.back_logo+')'}">
                    <img :src=item.logo />
                    <p>{{item.money}}</p>
                    <h1>{{item.title}}</h1>
                    <i class="iconfont" v-if="item.atv">&#xe645;</i>
                    <!-- <i class="iconfont atv">&#xe645;</i> -->
                    <!-- 阴影 -->
                    <span :class="{'mask': masks == index}"></span>
                    <span :class="{'mask': item.atv}"></span>
                </div>
            </li>
          </ul>
      </div>

      <!-- 活动规则 -->
      <div class="gz">
          <img src="../assets/img/NineLattice/gz.png" alt="">
          <div class="gz_data">
              <div class="gz_data_add" v-html="$options.filters.msg(content)"></div>
              <!-- <p>活动开始时间{{getInfoData.start_time}}，活动结束时间{{getInfoData.end_time}}。</p> -->
              <!-- <div class="gz_logo">
                <img src="../assets/img/NineLattice/gzlogo.png" alt="">
            </div> -->
          </div>
      </div>
      <div class="bottom">
          <p>活动最终解释权归cxskins所有，官方群：{{this.$store.state.webdata.qq_qun}}</p>
          <img src="../assets/img/cslogo.png" alt="">
      </div>
      <!-- 中奖弹出页 -->
      <Drawpr
      v-show="isDrawpr"
      :zhongjiangin='addprizeData'
      :isoutDrawp.sync = 'isDrawpr'
      :summoney="mmoney"
      :isaddprizeData='isaddprizeData'
      ></Drawpr>
      <!-- <Drawpr v-show="isDrawpr" :isoutDrawp.sync = 'isDrawpr' :zhongjiangin='zhongjiangin' :summoney.sync='summoney'
        ></Drawpr> -->
  </div>
</template>

<script>
import Drawpr from "../components/Drawprizes";
export default {
    components:{
        Drawpr
    },
    data(){
        return {
            // 奖单
            list: [],//奖品信息
            timer1: null,// 正常转动动画
            timer2: null,// 进入低速转动动画
            timer3: null,// 轮播信息定时前
            timer4: null,// 五分获取一次滚动结果接口定时器
            havePrize:[],//中奖过的
            masks:null,//中奖背景循环
            isprize:false,//中奖信息是否请求回来
            prizeData:'',//中奖信息第几个
            addprizeData:[{back_logo: "",code: 0,logo: "",money: "",p_title: "",title: "",type_title: "",w_id: "",username:""}],//中奖的信息
            isaddprizeData:0,
            NohavePrize:[],//没中过的
            Nomasks:0,//没中奖背景循环
            Notices:[],//轮播信息
            timer3:null,//中奖轮播定时器
            distance:0,//中奖轮播滚动距离
            distances:0,//中奖轮播结束后添加旧数据
            isrepages:false,//箱子开启中
            isDrawpr:false,//抽奖弹窗
            getInfoData:'',//页面信息
            content:'',//页面活动规则
            coin:'',//抽奖的金额 -1为全部获取
            mmoney:'',//中奖的价格
            //模拟中奖数据
            // a:[{id:0},{id:1},{id:2},{id:3},{id:4},{id:5},{id:6},{id:7},{id:8},{id:9}],//箱子数据 模拟上面的list
            // bb:[{id:1},{id:3},{id:4},{id:9}],//中奖过的
        }
    },
    created(){
        //页面信息
        this.getGetInfo()
        this.getShop()
    },
    mounted(){
        // 隐藏底部
        document.getElementsByClassName('footer')[0].style.display = 'none'
        //全局事件总线
        this.$bus.$on('Tokens',this.getGetInfo)
        //轮播抽奖结果滚动
        this.getList()

        //五分钟获取一次滚动接口
        this.timer4 = setInterval(() => {
            this.getLists()
        }, 300000);
        //用户抽奖信息
        // this.getUserShop()

        //中奖数据比较  可以获取数据时比较
        // for(let i = 0;i <= this.bb.length -1;i++ ){
        //     for(let j = 0;j <= this.a.length -1;j++){
        //         if(this.a[j].id== this.bb[i].id){
        //             this.havePrize.push(j)
        //         }
        //     }
        // }
        // //先把id取出来
        // for(let i=0;i <= this.a.length-1;i++){
        //     this.NohavePrize.push(this.a[i].id)
        // }
        // //赋值
        // this.NohavePrize = this.getArrDifference(this.havePrize,this.NohavePrize);

        // //给中奖的添加背景 可以在获取的时候添加
        // for(let i = 0;i < this.havePrize.length;i++){
        //     document.getElementsByClassName('mask_havePrize')[this.havePrize[i]].classList.add("mask")
        // }
        // //隐藏没中奖 可以获取数据时比较
        // for(let i = 0;i < this.bb.length;i++){
        //     document.getElementsByClassName('atv')[this.havePrize[i]].style.display = 'block'
        // }

    },
    destroyed(){
        clearInterval(this.timer1);
        clearInterval(this.timer2);
        clearInterval(this.timer3);
        clearInterval(this.timer4);
        document.getElementsByClassName('footer')[0].style.display = ''
    },
    computed:{
        //根据vuex判断是否是登录状态
        isToken(){
            if(this.$store.state.userdata.username != undefined){
                return false
            }else{
                return true
            }
        },
    },
    filters: {
        msg:function (msg) {
        return  msg
            .replace(/&amp;/g,'&')
            .replace(/&lt;/g, "<")
            .replace(/&gt;/g, ">")
            .replace(/&quot;/g, "\"")
            .replace(/&#39;/g, "\'");
        },
        //过滤器 中间的显示为***
        testfile (value) {
            const start = value.slice(0,2)
            const end = value.slice(-2)
            return `${start}***${end}`
        }
    },

    methods:{
        //获取轮播信息
        getList(){
            this.$axios({
                url:'/gongge/list',
                method:'post',
                data:{
                    aid:this.$route.query.id,
                }
            }).then (res => {
                if(res.data.status == 200){
                    this.Notices = res.data.data
                    this.timer3 = setInterval(() => {
                        this.distance++
                        if(this.distance >= this.Notices.length - 1 && this.Notices.length != 0){
                            this.Notices.push(this.Notices[this.distances])
                            this.distances++
                        }
                        document.getElementsByClassName("notice_data")[0].style.transition = "all 1s linear";
                        document.getElementsByClassName("notice_data")[0].style.marginTop = -(this.distance * 42) + "px";
                    }, 2000);

                }
            })
        },
        //获取轮播信息 单独调用
        getLists(){
            this.$axios({
                url:'/gongge/list',
                method:'post',
                data:{
                    aid:this.$route.query.id
                }
            }).then (res => {
                if(res.data.status == 200){
                    this.Notices.push(...res.data.data)
                }
            })
        },
        //一个返回差异数据的方法
        getArrDifference(arr1, arr2) {
            return arr1.concat(arr2).filter(function(v, i, arr) {
            return arr.indexOf(v) === arr.lastIndexOf(v);
            });
        },
        //登录
        handleZhu() {
            document.getElementsByClassName("form-wnd")[0].classList.add("active");
            document.getElementsByClassName("popup-bg")[0].classList.add("active");
            document.getElementsByClassName("switch-block")[0].classList.add("active");
            document.getElementsByClassName("switch-block")[1].classList.remove("active");
            document.getElementsByClassName("button")[0].classList.add("active");
            document.getElementsByClassName("button")[1].classList.remove("active");
        },
        //注册
        handleD() {
            document.getElementsByClassName("form-wnd")[0].classList.add("active");
            document.getElementsByClassName("popup-bg")[0].classList.add("active");
            document.getElementsByClassName("switch-block")[1].classList.add("active");
            document.getElementsByClassName("switch-block")[0].classList.remove("active");
            document.getElementsByClassName("button")[1].classList.add("active");
            document.getElementsByClassName("button")[0].classList.remove("active");
        },
        //退出登录
        isout() {
            //使用全局事件总线
            this.$bus.$emit('outToken')
            this.getGetInfo()
        },
        //获取活动信息
        getGetInfo(){
            this.$axios({
                url:'/gongge/getInfo',
                method:'post',
                data:{
                    aid:this.$route.query.id
                }
            }).then ( res => {
                if(res.data.status == 200){
                    this.getInfoData = res.data.data[0]
                    this.content = res.data.data[0].content
                    // document.getElementsByTagName("title")[0].innerText = res.data.data[0].title
                    if(localStorage.getItem("token")){
                        this.getUserShop()
                    }else{
                        this.coin = res.data.data[0].coin
                    }
                }
            })
        },
        //获取箱子奖品
        getShop(){
            this.$axios({
                url:'/gongge/getShop',
                method:'post',
                data:{
                    aid:this.$route.query.id
                }
            }).then (res => {
                if(res.data.status == 200){
                    this.list = res.data.data
                }
            })
        },
        //获取用户已经抽到的奖品！！！
        getUserShop(){
            this.$axios({
                url:'/gongge/getUserShop',
                method:'post',
                data:{
                    aid:this.$route.query.id
                }
            }).then ( res => {
                if(res.data.status == 200){
                    this.coin = res.data.coin
                    this.havePrize = res.data.data
                    // for(let i = 0;i <= res.data.data.length -1;i++ ){
                    //     for(let j = 0;j <= this.list.length -1;j++){
                    //         if(this.list[j].sid== res.data.data[i].sid){
                    //             this.havePrize.push(j)
                    //         }
                    //     }
                    // }
                    //给中过的奖添加一个标识
                    for(let i = 0;i < this.havePrize.length;i++){
                        for(let j = 0;j < this.list.length;j++){
                            if(this.list[j].id == this.havePrize[i].sid){
                                this.list[j]['atv']=true
                            }
                        }
                    }
                    //把没中过奖的取出来
                    //先清空
                    this.NohavePrize = []
                    for(let i = 0;i < this.list.length;i++ ){
                        if(!this.list[i].atv){
                            this.NohavePrize.push(i)
                        }
                    }
                }
            })
        },
        //抽奖
        nine(){
            if(localStorage.getItem("token")){
                if(!this.isrepages){//是否开启中
                    this.isrepages = true
                    clearInterval(this.timer1);
                    clearInterval(this.timer2);
                    // 发送请求
                    this.$axios({
                        url:"/gongge/draw",
                        method:'post',
                        data:{
                            aid:this.$route.query.id
                        }
                    }).then( res => {
                        if(res.data.status == 200){
                            //抽奖发起成功后减去余额
                            let data = parseFloat(this.$store.state.userdata.coin) - parseFloat(this.coin)
                            data = data.toFixed(2)
                            this.$store.commit("usercoin_up", data);
                            //如果最后一次
                            if(this.havePrize.length == this.list.length -1 ){
                                for(let i = 0;i < this.list.length;i++ ){
                                    if(this.list[i].id == res.data.data.id){
                                        this.prizeData = i
                                    }
                                }
                                //传递中奖信息过去
                                this.addprizeData[0].back_logo = this.list[this.prizeData].back_logo
                                this.addprizeData[0].logo = this.list[this.prizeData].logo
                                this.addprizeData[0].money = this.list[this.prizeData].money
                                this.addprizeData[0].type_title = this.list[this.prizeData].type_title
                                this.addprizeData[0].title = this.list[this.prizeData].title
                                this.mmoney = this.list[this.prizeData].money
                                this.addprizeData[0].w_id = res.data.data.w_id
                                this.addprizeData[0].username = this.$store.state.userdata.username
                                //弹出中奖提示框
                                this.getUserShop()
                                this.isDrawpr = true
                                this.Nomasks = 0
                                this.isrepages = false
                                this.Notices.push(...this.addprizeData) //把中奖信息添加到滚动栏
                            }else{
                                // 成功后
                                // 执行动画
                                this.masks = this.NohavePrize[0];
                                this.timer1 = setInterval(() => {
                                    if (this.masks == this.NohavePrize[this.NohavePrize.length - 1]) {
                                        this.Nomasks = 0
                                    }
                                    this.masks = this.NohavePrize[this.Nomasks];
                                    this.Nomasks++

                                    //请求回来就停止
                                    if (this.isprize) {
                                        clearInterval(this.timer1);
                                        this.enter(this.masks);
                                    }
                                }, 100);
                                //拿到中奖位置
                                for(let i = 0;i < this.list.length;i++ ){
                                    if(this.list[i].id == res.data.data.id){
                                        this.prizeData = i
                                    }
                                }
                                //传递中奖信息过去
                                this.addprizeData[0].back_logo = this.list[this.prizeData].back_logo
                                this.addprizeData[0].logo = this.list[this.prizeData].logo
                                this.addprizeData[0].money = this.list[this.prizeData].money
                                this.addprizeData[0].type_title = this.list[this.prizeData].type_title
                                this.addprizeData[0].title = this.list[this.prizeData].title
                                this.addprizeData[0].w_id = res.data.data.w_id
                                this.mmoney = this.list[this.prizeData].money
                                this.addprizeData[0].username = this.$store.state.userdata.username
                                setTimeout( ()=>{
                                    // this.prizeData = 2 //中奖位置
                                    this.isprize = true //进行下一步动画
                                },(Math.floor(Math.random() * (7 - 3  + 1)) + 3)*1000)
                            }
                        }else{

                            this.$message({
                                showClose: true,
                                message: res.data.msg,
                                type: 'error'
                            });
                            this.isrepages = false //动画结束 可以发起下一次抽奖
                        }
                    })
                    // 成功后
                    // 执行动画
                    // this.masks = this.NohavePrize[0];
                    // this.timer1 = setInterval(() => {
                    //     if (this.masks == this.NohavePrize[this.NohavePrize.length - 1]) {
                    //         this.Nomasks = 0
                    //     }
                    //     this.masks = this.NohavePrize[this.Nomasks];
                    //     this.Nomasks++

                    //     //请求回来就停止
                    //     if (this.isprize) {
                    //         clearInterval(this.timer1);
                    //         this.enter(this.masks);
                    //     }
                    // }, 100);
                }
            }else{
                this.$message({
                    showClose: true,
                    message: '请登录',
                    type: 'error'
                });
            }
        },
        //抽奖成功后结束动画
        enter(masks){
            this.masks = masks
            this.timer2 = setInterval(() => {
                if (this.masks == this.NohavePrize[this.NohavePrize.length - 1]) {
                    this.Nomasks = 0
                }
                this.masks = this.NohavePrize[this.Nomasks];
                this.Nomasks++

                //奖品位置对齐就停止
                if (this.prizeData == this.masks) {
                    clearInterval(this.timer2);
                    this.isprize = false
                    setTimeout( () =>{
                        this.masks = null
                        this.isDrawpr = true
                    },500)
                    this.getUserShop()
                    this.Nomasks = 0
                    this.isrepages = false //动画结束 可以发起下一次抽奖
                    this.Notices.push(...this.addprizeData) //把中奖信息添加到滚动栏
                }
            }, 300);
        },
    },
}
</script>

<style scoped lang="less">
//抽奖标题部分
.extract_prize{
    width: 1200px;
    margin: 10px auto 0;
}
.extract_prize_title{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0;
    ._title_h1{
        font-size: 22px;
        font-weight: bold;
        color: #c3c6fc;
        span{
            font-size: 22px;
            font-weight: bold;
            color: #ffc002;
            padding: 0 5px;
        }
    }
    ._title_span{
        font-size: 12px;
        font-weight: 400;
        color: #c3c6fc;
    }
}
.prize_ul{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    li{
        position: relative;
        margin: 0 .5%;
        margin-bottom: 1%;
        // width: 32%;
    }
}
.prize_ul_box{
    width: 369px;
    height: 184px;
    background-size: 100% 100%;
    background-repeat:no-repeat;
    background-position: center;
    background-image: url("../assets/img/NineLattice/cass_bg1.png");
    position: relative;
    img{
        width: 168px;
        height: 126px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    p{
        position: absolute;
        bottom: 20%;
        left: 5%;
        color: #f9b836;
        font-weight: bold;
        font-size: 22px;
        font-family: Alibaba PuHuiTi;
    }
    h1{
        position: absolute;
        bottom: 4%;
        left: 5%;
        color: #f9b836;
        font-weight: bold;
        font-size: 15px;
        font-family: Alibaba PuHuiTi;
    }
    i{
        font-size: 100px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}
.mask{
    position: absolute;
    left: 0;
    top: 0;
    z-index: 3;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .7);
    opacity: 1;
    border-radius: 20px;
}
//
.nine_title_backimg{
    width: 100%;
    height: 427px;
    // background-image: url("https://api.img.cxskins.com/img/user/logo/20210810//bccc79f22d853f0bf7d15a5c08a5b526.jpg");
    background-repeat:no-repeat;
    background-position: center;
    background-size: 100% 100%;
    .nine_title_data{
        padding: 0 20%;
        display: flex;
        .nine_title_data_img{
            margin-top: 15px;
            width: 235px;
            height: 56px;
        }
    }
}
.nine_title_data_user{
    margin-left:auto;
    padding-top: 5px;
    display: flex;
    .data_userout{
        color: #e7ac0f;
        font-size: 12px;
        cursor: pointer;
        margin-left: 10px;
    }
}
._user{
    display: flex;
    flex-direction: column;
    align-items: center;
    .data_user_img{
        width: 30px;
        height: 30px;
        border-radius: 50%;
        margin-right: 10px;
    }
    .data_user_name{
        margin-right: 10px;
        font-size: 12px;
    }

}
._user_wallet{
    display: flex;
    flex-direction: column;
    p{
        font-size: 12px;
        color: rgb(255, 152, 56);
        font-family: Microsoft YaHei;
        font-weight: 700 !important;
        padding-bottom: 6px;
    }
    button{
        border: none;
        font-size: 12px;
        display: block;
        margin: 0 auto;
        cursor: pointer;
        padding: 2px 10px;
        background-color: #e9b10e;
        color: #1a1c24;
        border-radius: 5px;
    }

}
.data_user_out{
    width: 217px;
    height: 42px;
    cursor: pointer;
    margin-left: 5px;
}
.nine_title_data_user_is{
    margin-left:auto;
    line-height: 42px;
    span{
        cursor: pointer;
        font-size: 14px;
    }
    i{
        padding:0 15px ;
    }
}

.Notice{
    width: 100%;
    height: 42px;
    background-image: url("../assets/img/NineLattice/Notice.png");
    // display: flex;
    // justify-content: center;
    // align-items: center;
    .notice_icon{
        padding-right: 10px;
        i{
            font-size: 25px;
        }
    }
}
.notices{
    height: 42px;
    overflow:hidden;
    line-height: 42px;
}
.notice_data{
    height: 42px;
    span{
        padding-right: 5px;
        font-size: 15px;
        color: #b5cfff;
    }
    .notice_data_prize{
        color: #d9e8fe;
        font-weight: 600;
    }
}
.boxtitle{
    width: 100%;
    margin-top: 30px;
    text-align: center;
    h1{
        font-size: 42px;
        letter-spacing: 5px;
        background-image:-webkit-linear-gradient(top,#fafafa,#b192ee);
        -webkit-background-clip:text;
        -webkit-text-fill-color:transparent;
    }
}
.boxdata{
    height: 257px;
    margin-top: 30px;
    background-image: url("../assets/img/NineLattice/cass.png");
    background-repeat:no-repeat;
    background-position: center;
    display: flex;
    justify-content: center;
    position: relative;
}
.boxdata_img{
    width: 357px;
    height: 230px;
    background-size: 100% 100%;
    // background-image: url("https://api.img.cxskins.com/img/user/logo/20210615/467170a265bed1349f45e393197ece85.png");
    background-repeat:no-repeat;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
}
.boxdata_img:hover{
    .box_prize_data_img{
        margin-bottom: 30px;
    }
}
.box_prize_data_img{
    width: 255px;
    height: 200px;
    background-size: 100% 100%;
    // background-image: url("https://api.img.cxskins.com/img/user/logo/20210725/c8cea6b03ffc494829accbbbd1a30e3b.png");
    background-repeat:no-repeat;
    background-position: center;
    transition: all .5s linear;
}
.boxdata_title{
    position:absolute;
    bottom: 15px;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 3px;
}
.box_but{
    button{
        width: 191px;
        height: 63px;
        background: url("../assets/img/NineLattice/btn.png") no-repeat;
        background-size: 100% 100%;
        display: block;
        margin: 0 auto;
        padding-bottom: 4px;
        font-weight: 600;
        color: #87450f;
        font-size: 16px;
        border: none;
        cursor: pointer;
    }
}
.gz{
    text-align: center;
}
.gz_data{
    position: relative;
    background-position: center;
    width: 1170px;
    // height: 364px;
    background-size: 100% 100%;
    background-repeat:no-repeat;
    background-image: url("../assets/img/NineLattice/gzbig.png");
    margin: 40px auto;
    padding: 30px 170px;
    p{
        font-size: 18px;
        line-height: 60px;
        text-align: left;
    }
    div{
        font-size: 18px;
        line-height: 60px;
        text-align: left;
    }
}
.gz_logo{
    position: absolute;
    bottom: -50px;
    right: -55px;
}

@media screen and (max-width: 800px) {
    .nine_title_backimg{
        // background-size: 250% 100%;
        height: 190px;
        margin-top: 5.25rem;;
    }
    .nine_title_data_user_is,.nine_title_data_user,.data_user_out{
        display: none;
    }
    .nine_title_data_img{
        padding-top: 15px !important;
    }
    .boxtitle{
        img{
            width: 100%;
        }
    }
    .extract_prize{
        width: 100%;
        margin: 10px auto 0;
    }
    ._title_h1{
        font-size: 12px !important;
    }
    .prize_ul_box{
        width: 100%;
        img{
            width: 98%;
        }
        p{
            font-size: 14px;
        }
        h1{
            width: 95%;
            display:block;
            white-space:nowrap;
            overflow:hidden;
            text-overflow:ellipsis;
            font-size: 12px;
        }
    }
    .prize_ul {
        li{
            position: relative;
            margin: 0 1.5%;
            margin-bottom: 2%;
            width: 45%;
        }
    }

    .mask{
        border-radius: 9px;
    }
    .gz{
        img{
            width: 100%;
        }
    }
    .gz_data{
        width: 100%;
        padding: 15px;
        p{
            font-size: 12px;
            line-height: 30px;
            position: relative;
            z-index: 9;
        }
    }
    .gz_logo{
        bottom: -70px;
        right: -15px;
    }
    .notice_san{
    width: 100% !important;
}
}

//三条轮播信息
.Notice{
    overflow:hidden;
}
.notice_san_big{
    display: flex;
    justify-content: center;
    align-items: center;
}
.notice_san{
    display: flex;
    width: 33%;
    justify-content: center;
    align-items: center;
}
.atv{
    display: none;
}
// 底部
.bottom{
    width: 100%;
    margin-top: 70px;
    background-color: #222f40;
    text-align: center;
    padding: 20px 0;
    font-size: 14px;
    font-weight: 400;
    color: #a9bcd6;
    line-height: 30px;
    img{
        width: 235px;
        height: 56px;
    }
}
</style>
<style>
.gz_data_add font{
    font-size: 18px !important;
}
</style>
